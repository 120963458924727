import visitorConstants from "@/constants/visitorConstants"
import { visitorApiV1NoCache } from "@/store/modules/axiousWithCache"

const getVisitorAdultsInitialState = () => {
  return {
    search: "",
    users: [],
    pagination: {
      activePage: 1,
      total: 0,
      pages: 0,
      per_page: { label: "25 / Page", value: 25 }
    }
  }
}

const state = {
  adults: getVisitorAdultsInitialState(),
  preApprovedList: {
    isLoading: false,
    data: [],
    pagination: {
      activePage: 1,
      total: 0,
      pages: 0,
      per_page: { label: "25 / Page", value: 25 }
    }
  },
  watchlist: {
    isLoading: false,
    data: [],
    pagination: {
      activePage: 1,
      total: 0,
      pages: 0,
      per_page: { label: "25 / Page", value: 25 }
    }
  },
  school: {
    data: null,
    sis: null,
    generalSettings: []
  },
  importStatus: {
    isLoading: false,
    data: null
  },
  printer: {
    printerId:
      localStorage.getItem(
        visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_ID
      ) || "",
    printerName:
      localStorage.getItem(
        visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_NAME
      ) || "",
    printerBrand:
      localStorage.getItem(
        visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_BRAND
      ) || "",
    printerFingerprint:
      localStorage.getItem(
        visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_FINGERPRINT
      ) || ""
  }
}

const getters = {
  adults: (state) => state?.adults || null,
  preApprovedList: (state) => state?.preApprovedList || null,
  watchlist: (state) => state?.watchlist || null,
  school: (state) => state?.school || null,
  printer: (state) => state?.printer || null,
  hasNoPrinter: (state) => {
    const p = state?.printer
    return (
      !p ||
      !p.printerId?.toString().trim() ||
      !p.printerName?.toString().trim() ||
      !p.printerBrand?.toString().trim() ||
      !p.printerFingerprint?.toString().trim()
    )
  },
  hasDymoPrinter: (state, getters) => {
    if (getters.hasNoPrinter) {
      return false
    }
    return (
      state?.printer?.printerBrand
        ?.toLowerCase()
        ?.includes(visitorConstants?.PRINTER_CONFIG?.DYMO?.BRAND) || false
    )
  },
  userAccess: (_state, _getters, _rootState, rootGetters) => {
    const currentRole = rootGetters["authentication/getUserRole"]
    if (visitorConstants.VISITOR_ADMIN_ROLES.includes(currentRole)) {
      return {
        visitorManagement: true,
        visitorModule: true,
        visitorSettings: true,
        visitorCreateMenu: true
      }
    }
    const allowVisitor =
      rootGetters?.["authentication/user"]?.allow_visitor || false
    return {
      visitorManagement: allowVisitor,
      visitorCreateMenu: allowVisitor,
      visitorModule: false,
      visitorSettings: false
    }
  },
  hasVisitorManagementAccess: (_state, getters) => {
    return getters?.userAccess?.visitorManagement || false
  },
  hasVisitorModuleAccess: (_state, getters) => {
    return getters?.userAccess?.visitorModule || false
  },
  hasVisitorSettingsAccess: (_state, getters) => {
    return getters?.userAccess?.visitorSettings || false
  },
  hasVisitorCreateMenuAccess: (_state, getters) => {
    return getters?.userAccess?.visitorCreateMenu || false
  },
  areAllTeachersStaffAllowed: (_state, _getters, _rootState, rootGetters) => {
    const visitorModuleOption =
      rootGetters?.["schools/visitorModuleOption"] || {}
    if (Object.keys(visitorModuleOption).length === 0) {
      return false
    }
    return visitorModuleOption?.["allow-teacher-staff-as-frontdesk"] || false
  }
}

const actions = {
  getRoles(context) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/shared/lookup/ROLE_CODE`)
        .then((response) => {
          const data = response.data.data
          context.commit("SET_ROLES", data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAdults(context) {
    const search = context?.state?.adults?.search || ""
    const start_record =
      Number(context?.state?.adults?.pagination?.activePage - 1) *
        Number(context?.state?.adults?.pagination?.per_page.value) || 0
    const total_record =
      Number(context.state?.adults?.pagination?.per_page.value) || 25
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/frontdesk/adults`, {
          params: {
            search,
            start_record,
            total_record
          }
        })
        .then((response) => {
          const data = response?.data?.data || {}
          context.commit("SET_ADULTS_DATA", data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadAdults(context, data) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post(`/frontdesk/csv`, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  assignSpecificAdults(context, data) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post(`/frontdesk/users/specific/flag`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateVisitorAdultsFlag(context, data) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .put(
          `/frontdesk/${visitorConstants.VISITOR_MODULE_ID}/users/flag`,
          data
        )
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateAdult(context, data) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .put(`/frontdesk/users`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteAdult(context, id) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .delete(`/frontdesk/users`, {
          data: {
            id
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadMatrix(context, data) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post(`/settings/student-guardians`, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  uploadWatchlist(context, data) {
    context.commit("SET_WATCHLIST_LOADING", true)
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post(`/watchlist`, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          context.commit("SET_WATCHLIST_LOADING", false)
        })
    })
  },
  createWatchlist(context, body) {
    context.commit("SET_WATCHLIST_LOADING", true)
    const user = {
      first_name: body.first_name,
      last_name: body.last_name,
      date_of_birth: body.date_of_birth,
      reason: body.reason
    }
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post(`/watchlist`, user)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          context.commit("SET_WATCHLIST_LOADING", false)
        })
    })
  },
  getWatchlist(context) {
    context.commit("SET_WATCHLIST_LOADING", true)
    return new Promise((resolve, reject) => {
      const start_record =
        Number(context.state.watchlist.pagination.activePage - 1) *
        Number(context.state.watchlist.pagination.per_page.value)
      const total_record = Number(
        context.state.watchlist.pagination.per_page.value
      )
      visitorApiV1NoCache
        .get(`/watchlist`, {
          params: {
            start_record,
            total_record
          }
        })
        .then((response) => {
          const data = response?.data || {}
          context.commit("SET_WATCHLIST_DATA", data?.data || {})
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          context.commit("SET_WATCHLIST_LOADING", false)
        })
    })
  },
  deleteWatchlist(context, id) {
    context.commit("SET_WATCHLIST_LOADING", true)
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .delete(`/watchlist`, {
          data: {
            id
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          context.commit("SET_WATCHLIST_LOADING", false)
        })
    })
  },
  getSchoolSettings(context) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/settings`)
        .then((response) => {
          const data = response.data
          const payload = {
            schoolData: data?.data?.result?.[0] || null,
            sisData: data?.data?.result?.[0]?.sis || null
          }
          context.commit("SET_SCHOOL_SETTINGS", payload)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getSchoolGeneralSettings(context) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/settings/general-settings`)
        .then((response) => {
          const settings = response?.data?.data || []
          context.commit("SET_SCHOOL_GENERAL_SETTINGS", settings)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateSchoolGeneralSettings(context, data) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .put(`/settings/update/general-settings`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getPrinters(context) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/printers`, {
          params: {
            fingerprint: context?.state?.printer?.printerFingerprint || ""
          }
        })
        .then((response) => {
          const data = response?.data?.data || []
          if (data?.length > 0) {
            context.commit("SET_PRINTER", data?.[0] || {})
          } else {
            context.commit("SET_PRINTER", {})
          }
          resolve(data)
        })
        .catch((err) => {
          context.commit("SET_PRINTER", {})
          reject(err)
        })
    })
  },
  deletePrinter(context, id) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .delete(`/printers`, {
          data: {
            data: { id }
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updatePrinter(context, payload) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post(`/printers`, {
          data: {
            ...payload
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  uploadSecurityStaff(context, data) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post(`/security-staff`, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createSecurityStaff(context, body) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post(`/security-staff`, {
          data: {
            first_name: body.first_name,
            last_name: body.last_name,
            email: body.email_address,
            phone_number: body.phone_number
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteSecurityStaff(context, id) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .delete(`/security-staff`, {
          data: {
            id
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadEmergencyContact(context, data) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post(`/emergency-contact`, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createEmergencyContact(context, body) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post(`/emergency-contact`, {
          data: {
            first_name: body.first_name,
            last_name: body.last_name,
            email: body.email_address,
            phone_number: body.phone_number
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteEmergencyContact(context, id) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .delete(`/emergency-contact`, {
          data: {
            id
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadPreApprovedList(context, data) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post(`/approved-visitors`, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createPreApprovedList(context, body) {
    context.commit("SET_PRE_APPROVED_LIST_LOADING", true)

    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post(`/approved-visitors`, {
          data: {
            first_name: body.first_name,
            last_name: body.last_name,
            date_of_birth: body.date_of_birth
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          context.commit("SET_PRE_APPROVED_LIST_LOADING", false)
        })
    })
  },
  getPreApprovedList(context) {
    context.commit("SET_PRE_APPROVED_LIST_LOADING", true)
    return new Promise((resolve, reject) => {
      const start_record =
        Number(context.state.preApprovedList.pagination.activePage - 1) *
        Number(context.state.preApprovedList.pagination.per_page.value)
      const total_record = Number(
        context.state.preApprovedList.pagination.per_page.value
      )
      visitorApiV1NoCache
        .get(`/approved-visitors`, {
          params: {
            start_record,
            total_record
          }
        })
        .then((response) => {
          const data = response?.data || {}
          context.commit("SET_PRE_APPROVED_LIST_DATA", data?.data || {})
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          context.commit("SET_PRE_APPROVED_LIST_LOADING", false)
        })
    })
  },
  deletePreApprovedList(context, id) {
    context.commit("SET_PRE_APPROVED_LIST_LOADING", true)
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .delete(`/approved-visitors`, {
          data: {
            id
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          context.commit("SET_PRE_APPROVED_LIST_LOADING", false)
        })
    })
  },
  updateSchoolSettings(context, body) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .put(`/settings/update`, {
          ...body
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getGuardianStudentImportStatus(context) {
    context.commit("SET_GUARDIAN_STUDENT_IMPORT_STATUS_LOADING", true)
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/settings/student-guardians/import-status`)
        .then((response) => {
          const data = response?.data?.data || null
          const importStatus = {
            totalMappings: data?.total_mappings || 0,
            uniqueGuardians: data?.unique_guardians || 0,
            lastImportLog: data?.last_import_log || {
              status: "",
              errors: []
            },
            lastImportAt: data?.last_import_at || ""
          }
          context.commit(
            "SET_GUARDIAN_STUDENT_IMPORT_STATUS_DATA",
            importStatus
          )
          resolve(importStatus)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          context.commit("SET_GUARDIAN_STUDENT_IMPORT_STATUS_LOADING", false)
        })
    })
  }
}

const mutations = {
  RESET_ADULTS_STATE(state) {
    state.adults = getVisitorAdultsInitialState()
  },
  SET_ADULTS_DATA(state, { total_count = 0, results = [] }) {
    state.adults.users = results
    state.adults.pagination.total = Number(total_count)
    state.adults.pagination.pages = Math.ceil(
      Number(total_count) / Number(state.adults.pagination.per_page.value)
    )
  },
  SET_ADULTS_SEARCH(state, search) {
    state.adults.search = search || ""
  },
  SET_ADULTS_PAGINATION_PER_PAGE(state, perPage) {
    perPage.label = perPage?.label || "25 / Page"
    perPage.value = perPage?.value || 25
    state.adults.pagination.per_page = {
      label: perPage.label,
      value: Number(perPage.value)
    }
    state.adults.pagination.activePage = 1
  },
  SET_ADULTS_PAGINATION_ACTIVE_PAGE(state, activePage) {
    state.adults.pagination.activePage = Number(activePage) || 1
  },
  SET_WATCHLIST_LOADING(state, isLoading) {
    state.watchlist.isLoading = isLoading
  },
  SET_WATCHLIST_DATA(state, { total_count = 0, results = [] }) {
    state.watchlist.data = results
    state.watchlist.pagination.total = Number(total_count)
    state.watchlist.pagination.pages = Math.ceil(
      Number(total_count) / Number(state.watchlist.pagination.per_page.value)
    )
  },
  SET_WATCHLIST_PAGINATION_PER_PAGE(state, perPage) {
    perPage.label = perPage?.label || "25 / Page"
    perPage.value = perPage?.value || 25
    state.watchlist.pagination.per_page = {
      label: perPage.label,
      value: Number(perPage.value)
    }
    state.watchlist.pagination.activePage = 1
  },
  SET_WATCHLIST_PAGINATION_ACTIVE_PAGE(state, activePage) {
    state.watchlist.pagination.activePage = Number(activePage)
  },
  SET_PRE_APPROVED_LIST_LOADING(state, isLoading) {
    state.preApprovedList.isLoading = isLoading
  },
  SET_PRE_APPROVED_LIST_DATA(state, { total_count = 0, results = [] }) {
    state.preApprovedList.data = results
    state.preApprovedList.pagination.total = Number(total_count)
    state.preApprovedList.pagination.pages = Math.ceil(
      Number(total_count) /
        Number(state.preApprovedList.pagination.per_page.value)
    )
  },
  SET_PRE_APPROVED_LIST_PAGINATION_PER_PAGE(state, perPage) {
    perPage.label = perPage?.label || "25 / Page"
    perPage.value = perPage?.value || 25
    state.preApprovedList.pagination.per_page = {
      label: perPage.label,
      value: Number(perPage.value)
    }
    state.preApprovedList.pagination.activePage = 1
  },
  SET_PRE_APPROVED_LIST_PAGINATION_ACTIVE_PAGE(state, activePage) {
    state.preApprovedList.pagination.activePage = Number(activePage) || 1
  },
  SET_SCHOOL_SETTINGS(state, { schoolData, sisData }) {
    state.school.data = schoolData || null
    state.school.sis = sisData || null
  },
  SET_SCHOOL_GENERAL_SETTINGS(state, settings) {
    state.school.generalSettings = settings || []
  },
  SET_PRINTER(state, printer) {
    state.printer = {
      printerId: printer?.id || "",
      printerName: printer?.printer_name || "",
      printerBrand: printer?.printer_brand || "",
      printerFingerprint:
        localStorage.getItem(
          visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_FINGERPRINT
        ) || ""
    }
  },
  SET_GUARDIAN_STUDENT_IMPORT_STATUS_DATA(state, data) {
    state.importStatus.data = data
  },
  SET_GUARDIAN_STUDENT_IMPORT_STATUS_LOADING(state, isLoading) {
    state.importStatus.isLoading = isLoading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
